//teachdigital.js
import ConsoleWrapper from './consoleWrapper';

var x = new ConsoleWrapper();
x.speak();

function arrow() {
  let foo = [1, 2, 3].map(x => x * 2);
  console.log(foo);
}

arrow();
